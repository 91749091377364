
/* Recoleta */
@font-face {
  font-family: 'Recoleta';
  src: url('./fonts/recoleta-light-webfont.woff2') format('woff2'),
       url('./fonts/recoleta-light-webfont.woff') format('woff');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('./fonts/recoleta-regular-webfont.woff2') format('woff2'),
       url('./fonts/recoleta-regular-webfont.woff') format('woff');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('./fonts/recoleta-medium-webfont.woff2') format('woff2'),
       url('./fonts/recoleta-medium-webfont.woff') format('woff');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('./fonts/recoleta-semibold-webfont.woff2') format('woff2'),
       url('./fonts/recoleta-semibold-webfont.woff') format('woff');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

/* Brandon Grotesque */

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./fonts/brandongrotesque-medium-webfont.woff2') format('woff2'),
       url('./fonts/brandongrotesque-medium-webfont.woff') format('woff');
  font-weight: 500; /* Medium */
  font-style: normal;
}


@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./fonts/brandongrotesque-bold-webfont.woff2') format('woff2'),
       url('./fonts/brandongrotesque-bold-webfont.woff') format('woff');
  font-weight: 700; /* Bold */
  font-style: normal;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

.row {
  @apply md:py-3;
}
.cell {
  @apply p-2 md:p-4 md:pl-8 md:pb-3 border-b border-slate-200;
}
.row:last-of-type .cell {
  @apply border-0;
}
/* 
button {
  @apply px-2 py-1 font-semibold text-slate-600 border border-slate-400 rounded-lg hover:bg-slate-100 transition-all;
} */

.bio p {
  @apply mb-12;
}

label {
  display: block;
  font-weight: 600;
}

body, html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Brandon Grotesque", sans-serif;  
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  background-color: rgb(43, 58, 136);
}



@media (min-width: 1280px) and (max-height:960px) {
  #intro {
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* Example for 16:9 aspect ratio */
    position: relative;
  }
}

#intro-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Additional styling for your content */
}


#intro::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%; 
  position: absolute;
  z-index: 2;  
  top: 0;
  left: 0;
  background-size: cover;
  background: linear-gradient(180deg, rgba(46, 65, 162, 0) 75.92%, rgba(43, 58, 136, 0.9) 100%), radial-gradient(circle, #2B3A88 0%, rgba(43, 58, 136, 0) 50%) center/80% 80% no-repeat;
}

@media (max-width: 1080px){
  #intro::before {
    background: linear-gradient(180deg, rgba(46, 65, 162, 0) 30.92%, rgba(43, 58, 136, 0.7) 100%) no-repeat;
  }
}

#nav * {
  transition: all 200ms ease;
  position: relative;
  top: 0;
}
#nav a:hover div {
  transform: scale(1.05);
}
#nav a:hover img {
  transform: rotate(-20deg);
  top: -8px;
}
#nav a:hover:first-child img {
  transform: rotate(-5deg);
}
#nav a:hover:last-child img {
  transform: rotate(5deg);
}

.carousel-cell img {
  transition: transform 1s ease;  
}

/* Rotate every 3rd image slightly differently */
.carousel-cell:nth-child(3n+1) img {
  transform: rotate(-2deg) scale(0.8);
}

.carousel-cell:nth-child(3n+2) img {
  transform: rotate(2deg) scale(0.8);
}

.carousel-cell:nth-child(3n) img {
  transform: rotate(-1deg) scale(0.8);
}

.carousel-cell.is-selected img {
  transform: rotate(0deg) scale(1);
}

.flickity-button {
  background-color: rgba(255, 255, 255, 0.316) !important;  
  @apply backdrop-blur-sm shadow-xl !border-solid !border-2 !border-white !h-10 !w-10 lg:!h-16 lg:!w-16 ;
}
.flickity-button-icon {
  fill: #fff !important;
}